<template>
    <ColumnaIzquierda></ColumnaIzquierda>
    <CreateCard></CreateCard>
</template>
<script>
import ColumnaIzquierda from '../components/ColumnaIzquierda/ColumnaIzquierda.vue';
import CreateCard from '../components/ColumnaDerecha/CreateCard.vue';
export default {
    name: 'CreateCardView',
    components: {
        ColumnaIzquierda,
        CreateCard
    }
}
</script>