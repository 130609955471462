import { createStore } from 'vuex';
import router from '../router'; // Asegúrate de la ruta correcta
import Swal from 'sweetalert2';
import { notify } from '@/swal';

// Función para obtener el estado inicial
const getDefaultState = () => {
  return {
    params: {},
    datosemp: {},
    optionSelected: '',
    datoscolor: {},
    registros: [],
    instrucciones: 'Introduce tu DNI',
    mode: 'dni',
    teletrabajo: false,
    dniGuardado: false,
    settings: {}, // Este campo se conservará
    photoData: false
  };
};

export default createStore({
  state: getDefaultState(),
  getters: {
  },
  mutations: {
    setParams(state, data) {
      state.params = data;
    },
    setDatosEmp(state, data) {
      state.datosemp = data;
    },
    setRegistros(state, data) {
      state.registros = data;
    },
    setSettings(state, data) {
      state.settings = data;
    },
    setPhotoData(state, data) {
      state.photoData = data;
    },
    async validarRegistro(state, accion) {
      if(state.datosemp.motivoAusenciaFutura != 'nada') {
          await Swal.fire({
              title: "Confirmar acción",
              text: "Actualmente estás ausente por "+state.datosemp.motivoAusenciaFutura+". Si continúas, tu ausencia se cancelará.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: state.datoscolor.colorbotones,
              cancelButtonColor: "#eb3b5a",
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
          }).then((result) => {
              if (result.isConfirmed) {
                state.mode = 'pin';
                state.instrucciones = 'Introduce el PIN para continuar';
                state.optionSelected = accion;
                router.push('/validate');
              } else {
                  // Si el usuario cancela, se detiene la función.
                  notify(true, null, 'No se ha añadido ningún registro', 'info');
              }
          });
      } else {
      state.mode = 'pin';
      state.instrucciones = 'Introduce el PIN para continuar';
      state.optionSelected = accion;
      router.push('/validate');
      }
    },
    setMode(state, data) {
      state.mode = data;
    },
    setInstrucciones(state, data) {
      state.instrucciones = data;
    },
    setTeletrabajo(state, data) {
      state.teletrabajo = data;
    },
    setDniGuardado(state, data) {
      state.dniGuardado = data;
    },
    RESET_STATE(state) {
      const currentSettings = state.settings; // Guarda los settings actuales
      Object.assign(state, getDefaultState());
      state.settings = currentSettings; // Restaura los settings
    }
  },
  actions: {
  },
  modules: {
  }
});